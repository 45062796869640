






















import alertS from '@/services/AlertService/AlertService';
import rService from '@/services/RequestService/RequestServiceSetup';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';


export default {
  name: 'Redeem',
  props: {},
  components: {
    RedeemDialog: () => import('@/components/Redeem/RedeemDialog.vue'),
  },
  mixins: [validationMixin],
  validations: {
    key: { required },
    checkbox: {
      checked(val) {
        return val;
      },
    },
  },
  data() {
    return {
      absolute: true,
      rules: [(value) => !!value || 'Required.', (value) => (value && value.length >= 36) || this.translate('charLong')],
      key: '',
      checkbox: false,
    };
  },
  methods: {
    ...mapActions(['updateUserInfo', 'openLogin']),
    translate(item: string) {
      return this.$vuetify.lang.t(`$vuetify.Redeem.${item}`);
    },
    async upgradeAnyways() {
      const Swal = this.$swal;
      const msg = "Current Plan hasn't finished yet and key is for a different role. If you continue with the upgrade you will lose the days remaining for this plan";
      Swal.fire({
        title: 'Alert!',
        text: msg,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, upgrade awanyays!',
      }).then(async (result) => {
        if (result.value) {
          this.submit(true);
        }
      })  
    },
    async redeemKey({key, upgradeForce}) {
      const res = await rService.post(`redeem_key/${this.userInfo.platform}`, { key, upgradeForce }, false, true);
      if (res && res.success) {
        this.$refs.dialog.close();
        this.updateUserInfo();
        alertS.success('Key redeemed successfully');
      } else {
        this.$refs.loadingChange(false);
      }
    },
    async submit(upgradeForce = false) {
      this.$v.checkbox.$touch();
      this.$v.key.$touch();
      if (this.keyErrors.length || this.checkboxErrors.length) return;
      alertS.loading(this.translate('checkingKey'));
      const res = await rService.post(`check_key/${this.userInfo.platform}`, { key: this.key, upgradeForce }, true, true);
      if (res && !res.error) {
        this.$swal.close();
        this.$refs.dialog.open(res, upgradeForce);
      } else if(res.error) {
        if(res.error === 'different_plan') {
          this.upgradeAnyways();
        } else {
          alertS.error(res.error);
        }
      }
    },
    clear() {
      this.key = '';
      this.checkbox = false;
    },
  },
  watch: {
    key(newVal: string) {
      this.key = newVal.toUpperCase();
    },
  },
  computed: {
    ...mapGetters(['userInfo', 'loggedIn']),
    keyErrors() {
      const errors = [];
      if (!this.$v.key.$dirty) return errors;
      !(this.key.length >= 36) && errors.push(this.translate('charLong'));
      !this.$v.key.required && errors.push(this.translate('required'));
      return errors;
    },
    checkboxErrors() {
      const errors = [];
      if (!this.$v.checkbox.$dirty) return errors;
      !this.$v.checkbox.checked && errors.push(this.translate('mustAgree'));
      return errors;
    },
  },
};
